import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { IRemoteSchema, OASWalkerConstants } from 'oas-changeset-walker'
import { PathsObject } from 'openapi3-ts'
import React, { useCallback, useEffect, useReducer, useRef } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import Select from 'react-select'

import { IReactSelectOption } from '../interfaces'
import { parseSpec } from '../utils'

interface IState {
  readonly paths: PathsObject | null
  readonly specOptions: IReactSelectOption[]
  readonly endpointOptions: IReactSelectOption[]
  readonly methodOptions: IReactSelectOption[]
  readonly specLoading: boolean
  readonly selectedSpecId: string | null
  readonly selectedEndpoint: string | null
  readonly selectedMethod: string | null
}

enum ReducerType {
  specLoading = 'specLoading',
  specChanged = 'specChanged',
  specLoaded = 'specLoaded',
  endpointChanged = 'endpointChanged',
  methodChanged = 'methodChanged',
}

interface IReducerAction {
  type: ReducerType
  paths?: PathsObject
  specOptions?: IReactSelectOption[]
  endpointOptions?: IReactSelectOption[]
  methodOptions?: IReactSelectOption[]
  selectedSpecId?: string
  selectedEndpoint?: string
  selectedMethod?: string
}

const formatSpecOptions = (schemas: IRemoteSchema[]): IReactSelectOption[] => {
  return schemas.map(schema => {
    return { value: schema.id, label: schema.id }
  })
}

const initialState = {
  endpointOptions: [],
  methodOptions: [],
  paths: null,
  selectedEndpoint: null,
  selectedMethod: null,
  selectedSpecId: null,
  specLoading: false,
  specOptions: formatSpecOptions(OASWalkerConstants.API_SPECS),
} as IState

function reducer(state: IState, action: IReducerAction): IState {
  switch (action.type) {
    case ReducerType.specLoading:
      return {
        ...state,
        specLoading: true,
      }
    case ReducerType.specChanged:
      if (action.selectedSpecId) {
        return {
          ...state,
          selectedSpecId: action.selectedSpecId,
        }
      }
    case ReducerType.specLoaded:
      if (action.endpointOptions && action.paths) {
        return {
          ...state,
          endpointOptions: action.endpointOptions,
          paths: action.paths,
          specLoading: false,
        }
      }
    case ReducerType.endpointChanged:
      if (action.selectedEndpoint) {
        return {
          ...state,
          methodOptions: state.paths
            ? formatPathOptions(state.paths[action.selectedEndpoint])
            : [],
          selectedEndpoint: action.selectedEndpoint,
        }
      }
    case ReducerType.methodChanged:
      if (action.selectedMethod) {
        return { ...state, selectedMethod: action.selectedMethod }
      }
    default:
      throw new Error()
  }
}

const formatPathOptions = (options: PathsObject): IReactSelectOption[] => {
  if (!options) {
    return []
  }

  return Object.keys(options).map(key => {
    return { value: key, label: key }
  })
}

function EntryPoint(props: RouteComponentProps) {
  const [state, dispatch] = useReducer(reducer, initialState)
  const methodSelectEl = useRef(null)

  useEffect(() => {
    dispatch({ type: ReducerType.specLoading })
    const fetchSpec = async (specId: string) => {
      const spec = await parseSpec(specId)
      dispatch({
        endpointOptions: formatPathOptions(spec.paths),
        paths: spec.paths,
        type: ReducerType.specLoaded,
      })
    }
    if (state.selectedSpecId) {
      fetchSpec(state.selectedSpecId)
    }
  }, [state.selectedSpecId])

  return (
    <Grid container={true} spacing={40}>
      <Grid item={true}>
        <Typography component="h1" variant="h4" gutterBottom={true}>
          Select Endpoint & Method
        </Typography>
      </Grid>

      <Grid container={true} item={true} spacing={40}>
        <Grid item={true} xs={8}>
          <Select
            placeholder={'Select Spec...'}
            isLoading={!state.specOptions}
            options={state.specOptions}
            onChange={useCallback(
              newValue => {
                if (methodSelectEl && methodSelectEl.current) {
                  ;(methodSelectEl.current as any).select.clearValue()
                }

                dispatch({
                  selectedSpecId: newValue.value,
                  type: ReducerType.specChanged,
                })
              },
              [state.selectedSpecId],
            )}
          />
        </Grid>

        <Grid item={true} xs={8}>
          <Select
            placeholder={'Select Endpoint...'}
            isDisabled={!state.selectedSpecId || state.specLoading}
            isLoading={!state.endpointOptions || state.specLoading}
            options={state.endpointOptions}
            onChange={useCallback(
              newValue => {
                if (methodSelectEl && methodSelectEl.current) {
                  ;(methodSelectEl.current as any).select.clearValue()
                }

                dispatch({
                  selectedEndpoint: newValue.value,
                  type: ReducerType.endpointChanged,
                })
              },
              [state.selectedEndpoint],
            )}
          />
        </Grid>

        <Grid item={true} xs={8}>
          <Select
            placeholder={'Select Method...'}
            isDisabled={!state.selectedEndpoint}
            options={state.methodOptions}
            onChange={useCallback(newValue => {
              if (!newValue) {
                return
              }

              dispatch({
                selectedMethod: newValue.value,
                type: ReducerType.methodChanged,
              })
            }, [])}
            ref={methodSelectEl}
          />
        </Grid>

        <Grid item={true} xs={12}>
          <Button
            color="primary"
            variant="contained"
            disabled={!state.selectedMethod}
            onClick={useCallback(() => {
              if (
                state.selectedSpecId &&
                state.selectedEndpoint &&
                state.selectedMethod
              ) {
                props.history.push(
                  `/editor/${encodeURIComponent(
                    state.selectedSpecId,
                  )}/${encodeURIComponent(
                    state.selectedEndpoint,
                  )}/${encodeURIComponent(state.selectedMethod)}`,
                )
              }
            }, [
              state.selectedSpecId,
              state.selectedEndpoint,
              state.selectedMethod,
            ])}
          >
            Create / Edit Flavor
          </Button>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default EntryPoint
