import {
  Chip,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'
import cloneDeep from 'lodash/cloneDeep'
import {
  ChangesetOperation,
  getChange,
  getFirstContentTypeFromContent,
  IChangeset,
  ISchemaObject,
  ISpecWalkerMeta,
  OASWalkerConstants,
  SchemaWalkerContextType,
  walk,
} from 'oas-changeset-walker'
import { OpenAPIObject, ResponseObject } from 'openapi3-ts'
import React, { Dispatch, useCallback, useState } from 'react'
import {
  Accordion,
  AccordionItem,
  AccordionItemBody,
  AccordionItemTitle,
} from 'react-accessible-accordion'
import 'react-accessible-accordion/dist/fancy-example.css'

import Constants from '../../constants'
import { EditorReducerType } from '../../enums'
import { IEditorReducerAction } from '../../interfaces'
import CollapsibleItem from './collapsible-item'

interface IProps {
  readonly responseTitle: string
  readonly response: ResponseObject
  readonly changeset: IChangeset[]
  readonly spec: OpenAPIObject
  readonly dispatch: Dispatch<IEditorReducerAction>
}

function buildFirstResponsesRender(
  schemaObj: ResponseObject,
  changeset: IChangeset[],
  spec: OpenAPIObject,
  dispatch: Dispatch<IEditorReducerAction>,
  topLevelKey: string,
  contentType: string,
) {
  return walk(schemaObj, contentType, {
    context: {
      topLevelKey,
      type: SchemaWalkerContextType.responses,
    },
  }).map((walkerMeta: ISpecWalkerMeta) => {
    return (
      <CollapsibleItem
        key={walkerMeta.schema[OASWalkerConstants.X_SCHEMA_WALKER].path}
        itemTitle={walkerMeta.title}
        schema={walkerMeta.schema as ISchemaObject}
        changeset={changeset}
        spec={spec}
        dispatch={dispatch}
      />
    )
  })
}

function Response(props: IProps) {
  const { changeset, spec, dispatch } = props
  const { responseTitle, response } = props

  const baseResponseKey = `responses['${props.responseTitle}']`

  const foundChanges = changeset.filter(
    existingChange =>
      existingChange.path === baseResponseKey + '.description' ||
      existingChange.path === baseResponseKey, // Readd or remove
  )

  const deletedChange = getChange(foundChanges, baseResponseKey)

  const foundDescriptionChange = getChange(
    foundChanges,
    baseResponseKey + '.description',
  )

  const beenDeleted =
    foundChanges &&
    deletedChange &&
    deletedChange.operation === ChangesetOperation.destroy

  const [deletable, setDeletable] = useState(!beenDeleted)

  let contentType = null
  if (response && response.content) {
    contentType = getFirstContentTypeFromContent(response.content)
  }

  return (
    <>
      <Accordion>
        <AccordionItem>
          <AccordionItemTitle
            className={
              beenDeleted
                ? 'accordion__title accordion__title--disabled'
                : 'accordion__title'
            }
          >
            <Grid container={true} alignItems="center">
              <Grid
                container={true}
                item={true}
                xs={true}
                spacing={16}
                alignItems="center"
              >
                <Grid item={true}>
                  <Typography component="h6" variant="subtitle1">
                    {props.responseTitle}
                  </Typography>
                </Grid>

                <Grid item={true}>
                  <Chip
                    label="object"
                    style={{
                      backgroundColor: Constants.TAG_TYPE_MAPPING.object,
                    }}
                  />
                </Grid>
              </Grid>

              <Grid item={true} xs={1}>
                {deletable ? (
                  <Tooltip title="Mark for Deletion" placement="top-end">
                    <Typography align="right">
                      <IconButton
                        aria-label="Mark for Deletion"
                        onClick={useCallback(
                          event => {
                            // Disable the item
                            const titleEl = event.target.closest(
                              '.accordion__title',
                            )
                            titleEl.setAttribute('aria-selected', false)
                            titleEl.classList.add('accordion__title--disabled')

                            const bodyEl = document.getElementById(
                              titleEl.getAttribute('aria-controls'),
                            )

                            if (bodyEl) {
                              bodyEl.classList.add('accordion__body--hidden')
                              bodyEl.setAttribute('aria-hidden', 'true')
                            }

                            const buttonEl = event.target.closest('button')
                            buttonEl.style.pointerEvents = 'all'

                            event.stopPropagation()
                            setDeletable(false)

                            dispatch({
                              change: {
                                operation: ChangesetOperation.destroy,
                                path: buttonEl.dataset.path,
                              },
                              type: EditorReducerType.fieldChanged,
                            })
                          },
                          [deletable],
                        )}
                        data-path={baseResponseKey}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Typography>
                  </Tooltip>
                ) : (
                  <Tooltip title="Readd" placement="top-end">
                    <Typography align="right">
                      <IconButton
                        aria-label="Readd"
                        onClick={useCallback(
                          event => {
                            // Reenable the item
                            const titleEl = event.target.closest(
                              '.accordion__title',
                            )
                            titleEl.classList.remove(
                              'accordion__title--disabled',
                            )

                            const buttonEl = event.target.closest('button')
                            buttonEl.style.pointerEvents = 'auto'
                            event.stopPropagation()
                            setDeletable(true)

                            dispatch({
                              change: {
                                operation: ChangesetOperation.destroy,
                                path: event.target.closest('button').dataset
                                  .path,
                              },
                              type: EditorReducerType.fieldChanged,
                            })
                          },
                          [deletable],
                        )}
                        data-path={baseResponseKey}
                      >
                        <AddIcon />
                      </IconButton>
                    </Typography>
                  </Tooltip>
                )}
              </Grid>
            </Grid>
          </AccordionItemTitle>

          <AccordionItemBody>
            <Grid container={true} spacing={8} alignItems="center">
              <Grid container={true} item={true} spacing={16}>
                <Grid item={true} xs={12}>
                  <TextField
                    label="Description"
                    defaultValue={
                      foundDescriptionChange &&
                      foundDescriptionChange.operation ===
                        ChangesetOperation.update &&
                      foundDescriptionChange.value
                        ? foundDescriptionChange.value.toString()
                        : response.description
                    }
                    margin="normal"
                    multiline={true}
                    fullWidth={true}
                    onChange={useCallback(event => {
                      dispatch({
                        change: {
                          operation: ChangesetOperation.update,
                          path: event.target.dataset.path,
                          value: event.target.value,
                        },
                        type: EditorReducerType.fieldChanged,
                      })
                    }, [])}
                    inputProps={{
                      'data-path': baseResponseKey + '.description',
                    }}
                  />
                </Grid>
              </Grid>

              <Grid item={true} xs={12}>
                {response && response.content && contentType
                  ? buildFirstResponsesRender(
                      (response.content[contentType] as any).schema,
                      props.changeset,
                      props.spec,
                      props.dispatch,
                      responseTitle,
                      contentType,
                    )
                  : null}
              </Grid>
            </Grid>
          </AccordionItemBody>
        </AccordionItem>
      </Accordion>
    </>
  )
}

export default Response
