import {
  Chip,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core'
import red from '@material-ui/core/colors/red'
import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'
import cloneDeep from 'lodash/cloneDeep'
import {
  ChangesetOperation,
  getChange,
  getSchemaFromRef,
  IChangeset,
  ISchemaObject,
  OASWalkerConstants,
  SchemaWalkerContextType,
  walk,
} from 'oas-changeset-walker'
import { OpenAPIObject } from 'openapi3-ts'
import React, { Dispatch, useCallback, useState } from 'react'
import {
  Accordion,
  AccordionItem,
  AccordionItemBody,
  AccordionItemTitle,
} from 'react-accessible-accordion'
import 'react-accessible-accordion/dist/fancy-example.css'

import Constants from '../../constants'
import { EditorReducerType } from '../../enums'
import { IEditorReducerAction } from '../../interfaces'
import './collapsible-item.css'

interface IProps {
  readonly itemTitle: string
  readonly schema: ISchemaObject
  readonly changeset: IChangeset[]
  readonly spec: OpenAPIObject
  readonly dispatch: Dispatch<IEditorReducerAction>
}

function getDerefedSchema(schema: ISchemaObject, spec: OpenAPIObject) {
  const derefedSchema = getSchemaFromRef(schema.$ref, spec)
  derefedSchema[OASWalkerConstants.X_SCHEMA_WALKER] = cloneDeep(
    schema[OASWalkerConstants.X_SCHEMA_WALKER],
  )

  // The type doesn't matter here since we're getting it from the parent context
  const walkedSchema = walk(derefedSchema, '', {
    context: { type: SchemaWalkerContextType.requestBody },
  })[0].schema
  return walkedSchema
}

function CollapsibleItem(props: IProps) {
  const { changeset, spec, dispatch } = props
  let { schema } = props

  const foundChanges = changeset.filter(
    existingChange =>
      existingChange.path ===
      schema[OASWalkerConstants.X_SCHEMA_WALKER].path + '.description' ||
      existingChange.path ===
      schema[OASWalkerConstants.X_SCHEMA_WALKER].path + '.example' ||
      existingChange.path === schema[OASWalkerConstants.X_SCHEMA_WALKER].path,
  )

  const deletedChange = getChange(
    foundChanges,
    schema[OASWalkerConstants.X_SCHEMA_WALKER].path,
  )

  const foundDescriptionChange = getChange(
    foundChanges,
    schema[OASWalkerConstants.X_SCHEMA_WALKER].path + '.description',
  )

  const foundExampleChange = getChange(
    foundChanges,
    schema[OASWalkerConstants.X_SCHEMA_WALKER].path + '.example',
  )

  const beenDeleted =
    foundChanges &&
    deletedChange &&
    deletedChange.operation === ChangesetOperation.destroy

  if (schema.$ref) {
    schema = {
      ...schema,
      ...getDerefedSchema(schema, spec),
    }
  }

  const [deletable, setDeletable] = useState(!beenDeleted)

  return (
    <>
      <Accordion>
        <AccordionItem>
          <AccordionItemTitle
            className={
              beenDeleted
                ? 'accordion__title accordion__title--disabled'
                : 'accordion__title'
            }
          >
            <Grid container={true} alignItems="center">
              <Grid
                container={true}
                item={true}
                xs={true}
                spacing={16}
                alignItems="center"
              >
                <Grid item={true}>
                  <Typography component="h6" variant="subtitle1">
                    {schema.$ref
                      ? schema.$ref
                      : props.itemTitle
                        ? props.itemTitle
                        : schema.title}
                  </Typography>
                </Grid>

                {schema.type ? (
                  <Grid item={true}>
                    <Chip
                      label={schema.type}
                      style={{
                        backgroundColor:
                          Constants.TAG_TYPE_MAPPING[schema.type],
                      }}
                    />
                  </Grid>
                ) : null}

                {schema.required ? (
                  <Grid item={true}>
                    <Chip
                      label="REQUIRED"
                      style={{
                        backgroundColor: red[500],
                      }}
                    />
                  </Grid>
                ) : null}
              </Grid>

              <Grid item={true} xs={1}>
                {deletable ? (
                  <Tooltip title="Mark for Deletion" placement="top-end">
                    <Typography align="right">
                      <IconButton
                        aria-label="Mark for Deletion"
                        disabled={!!schema.required}
                        onClick={useCallback(
                          event => {
                            // Disable the item
                            const titleEl = event.target.closest(
                              '.accordion__title',
                            )
                            titleEl.setAttribute('aria-selected', false)
                            titleEl.classList.add('accordion__title--disabled')

                            const bodyEl = document.getElementById(
                              titleEl.getAttribute('aria-controls'),
                            )

                            if (bodyEl) {
                              bodyEl.classList.add('accordion__body--hidden')
                              bodyEl.setAttribute('aria-hidden', 'true')
                            }

                            const buttonEl = event.target.closest('button')
                            buttonEl.style.pointerEvents = 'all'

                            event.stopPropagation()
                            setDeletable(false)

                            dispatch({
                              change: {
                                operation: ChangesetOperation.destroy,
                                path: buttonEl.dataset.path,
                              },
                              type: EditorReducerType.fieldChanged,
                            })
                          },
                          [deletable],
                        )}
                        data-path={
                          schema[OASWalkerConstants.X_SCHEMA_WALKER].path
                        }
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Typography>
                  </Tooltip>
                ) : (
                    <Tooltip title="Readd" placement="top-end">
                      <Typography align="right">
                        <IconButton
                          aria-label="Readd"
                          disabled={!!schema.required}
                          onClick={useCallback(
                            event => {
                              // Reenable the item
                              const titleEl = event.target.closest(
                                '.accordion__title',
                              )
                              titleEl.classList.remove(
                                'accordion__title--disabled',
                              )

                              const buttonEl = event.target.closest('button')
                              buttonEl.style.pointerEvents = 'auto'
                              event.stopPropagation()
                              setDeletable(true)

                              dispatch({
                                change: {
                                  operation: ChangesetOperation.destroy,
                                  path: event.target.closest('button').dataset
                                    .path,
                                },
                                type: EditorReducerType.fieldChanged,
                              })
                            },
                            [deletable],
                          )}
                          data-path={
                            schema[OASWalkerConstants.X_SCHEMA_WALKER].path
                          }
                        >
                          <AddIcon />
                        </IconButton>
                      </Typography>
                    </Tooltip>
                  )}
              </Grid>
            </Grid>
          </AccordionItemTitle>

          <AccordionItemBody>
            <Grid container={true} spacing={8} alignItems="center">
              <Grid container={true} item={true} spacing={16}>
                <Grid item={true} xs={12}>
                  <TextField
                    label="Description"
                    defaultValue={
                      foundDescriptionChange &&
                        foundDescriptionChange.operation ===
                        ChangesetOperation.update &&
                        foundDescriptionChange.value
                        ? foundDescriptionChange.value.toString()
                        : schema.description
                    }
                    margin="normal"
                    multiline={true}
                    fullWidth={true}
                    onChange={useCallback(event => {
                      dispatch({
                        change: {
                          operation: ChangesetOperation.update,
                          path: event.target.dataset.path,
                          value: event.target.value,
                        },
                        type: EditorReducerType.fieldChanged,
                      })
                    }, [])}
                    inputProps={{
                      'data-path':
                        schema[OASWalkerConstants.X_SCHEMA_WALKER].path +
                        '.description',
                    }}
                  />
                </Grid>

                {!(schema.type === 'object' || schema.type === 'array') ? (
                  <Grid item={true} xs={12}>
                    <TextField
                      label="Example"
                      defaultValue={
                        foundExampleChange &&
                          foundExampleChange.operation ===
                          ChangesetOperation.update &&
                          foundExampleChange.value
                          ? foundExampleChange.value.toString()
                          : schema.example
                      }
                      margin="normal"
                      fullWidth={true}
                      inputProps={{
                        'data-path':
                          schema[OASWalkerConstants.X_SCHEMA_WALKER].path +
                          '.example',
                      }}
                      onChange={useCallback(event => {
                        dispatch({
                          change: {
                            operation: ChangesetOperation.update,
                            path: event.target.dataset.path,
                            value: event.target.value,
                          },
                          type: EditorReducerType.fieldChanged,
                        })
                      }, [])}
                    />
                  </Grid>
                ) : null}

                {schema.enum ? (
                  <Grid item={true} xs={12}>
                    <TextField
                      disabled={true}
                      label="Enum"
                      defaultValue={schema.enum.join(', ')}
                      margin="normal"
                      fullWidth={true}
                    />
                  </Grid>
                ) : null}

                {schema.format ? (
                  <Grid item={true} xs={12}>
                    <TextField
                      disabled={true}
                      label="Format"
                      defaultValue={schema.format}
                      margin="normal"
                    />
                  </Grid>
                ) : null}

                {schema.maximum ? (
                  <Grid item={true} xs={12}>
                    <TextField
                      disabled={true}
                      label="Maximum"
                      defaultValue={schema.maximum}
                      margin="normal"
                    />
                  </Grid>
                ) : null}

                {schema.minimum ? (
                  <Grid item={true} xs={12}>
                    <TextField
                      disabled={true}
                      label="Minimum"
                      defaultValue={schema.minimum}
                      margin="normal"
                    />
                  </Grid>
                ) : null}

                {schema.maxLength ? (
                  <Grid item={true} xs={12}>
                    <TextField
                      disabled={true}
                      label="Max Length"
                      defaultValue={schema.maxLength}
                      margin="normal"
                    />
                  </Grid>
                ) : null}

                {schema.minLength ? (
                  <Grid item={true} xs={12}>
                    <TextField
                      disabled={true}
                      label="Min Length"
                      defaultValue={schema.minLength}
                      margin="normal"
                    />
                  </Grid>
                ) : null}

                {schema.pattern ? (
                  <Grid item={true} xs={12}>
                    <TextField
                      disabled={true}
                      label="Pattern"
                      defaultValue={schema.pattern}
                      margin="normal"
                      fullWidth={true}
                    />
                  </Grid>
                ) : null}
              </Grid>

              <Grid item={true} xs={12}>
                {schema.type === 'array' && schema.items ? (
                  <CollapsibleItem
                    {...props}
                    schema={schema.items as ISchemaObject}
                  />
                ) : null}

                {schema.type === 'object' && schema.properties
                  ? Object.entries(schema.properties).map(entry => {
                    const newSchema = entry[1] as ISchemaObject;
                    if(newSchema && newSchema[OASWalkerConstants.X_SCHEMA_WALKER] && newSchema[OASWalkerConstants.X_SCHEMA_WALKER].path) {
                   
                    return (
                      <CollapsibleItem
                          {...props}
                          schema={newSchema}
                          itemTitle={entry[0]}
                          key={
                            newSchema[OASWalkerConstants.X_SCHEMA_WALKER].path
                          }
                        />
                  )
                  }
                    })
              : null}

                {schema.allOf ? (
                  <Grid container={true} spacing={16}>
                    <Grid item={true} xs={12}>
                      <Chip label="ALL OF" color="primary" />
                    </Grid>

                    <Grid item={true} xs={12}>
                      {Object.entries(schema.allOf).map(entry => {
                        const newSchema = entry[1] as ISchemaObject

                        return (
                          <CollapsibleItem
                            {...props}
                            schema={newSchema}
                            itemTitle={
                              newSchema.$ref
                                ? newSchema.$ref
                                : `Item ${parseInt(entry[0]) + 1}`
                            }
                            key={
                              newSchema[OASWalkerConstants.X_SCHEMA_WALKER]
                                .path + 'allOf'
                            }
                          />
                        )
                      })}
                    </Grid>
                  </Grid>
                ) : null}

                {schema.anyOf ? (
                  <Grid container={true} spacing={16}>
                    <Grid item={true} xs={12}>
                      <Chip label="ANY OF" color="primary" />
                    </Grid>

                    <Grid item={true} xs={12}>
                      {Object.entries(schema.anyOf).map(entry => {
                        const newSchema = entry[1] as ISchemaObject

                        return (
                          <CollapsibleItem
                            {...props}
                            schema={newSchema}
                            itemTitle={
                              newSchema.$ref
                                ? newSchema.$ref
                                : `Item ${parseInt(entry[0]) + 1}`
                            }
                            key={
                              newSchema[OASWalkerConstants.X_SCHEMA_WALKER]
                                .path + 'anyOf'
                            }
                          />
                        )
                      })}
                    </Grid>
                  </Grid>
                ) : null}

                {schema.oneOf ? (
                  <Grid container={true} spacing={16}>
                    <Grid item={true} xs={12}>
                      <Chip label="ONE OF" color="primary" />
                    </Grid>

                    <Grid item={true} xs={12}>
                      {Object.entries(schema.oneOf).map(entry => {
                        const newSchema = entry[1] as ISchemaObject

                        return (
                          <CollapsibleItem
                            {...props}
                            schema={newSchema}
                            itemTitle={
                              newSchema.$ref
                                ? newSchema.$ref
                                : `Item ${parseInt(entry[0]) + 1}`
                            }
                            key={
                              newSchema[OASWalkerConstants.X_SCHEMA_WALKER]
                                .path + 'oneOf'
                            }
                          />
                        )
                      })}
                    </Grid>
                  </Grid>
                ) : null}
              </Grid>
            </Grid>
          </AccordionItemBody>
        </AccordionItem>
      </Accordion>
    </>
  )
}

export default CollapsibleItem
