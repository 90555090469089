import { Typography } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import LinearProgress from '@material-ui/core/LinearProgress'
import Link from '@material-ui/core/Link'
import TextField from '@material-ui/core/TextField'
import debounce from 'lodash/debounce'
import {
  ChangesetOperation,
  getFirstContentTypeFromContent,
  IChangeset,
  IOperationMethod,
  IPersistedData,
  ISchemaObject,
  ISpecWalkerMeta,
  OASWalkerConstants,
  SchemaWalkerContextType,
  walk,
} from 'oas-changeset-walker'
import {
  OpenAPIObject,
  OperationObject,
  ParameterObject,
  RequestBodyObject,
  ResponseObject,
  SchemaObject,
} from 'openapi3-ts'
import React, { Dispatch, useEffect, useMemo, useReducer } from 'react'
import { RouteComponentProps } from 'react-router'
import { Link as RouterLink } from 'react-router-dom'

import { EditorReducerType } from '../enums'
import { IEditorReducerAction } from '../interfaces'
import { loadSpec } from '../utils'
import CollapsibleItem from './components/collapsible-item'
import Parameter from './components/parameter'
import Response from './components/response'

interface IState {
  readonly specId: string | null
  readonly endpoint: string | null
  readonly method: string | null
  readonly operation: OperationObject | null
  readonly spec: OpenAPIObject | null
  readonly changeset: IChangeset[]
  readonly loaded: boolean
}

interface IRouteParams {
  path: string
  method: string
  specId: string
}

const initialState = {
  changeset: [],
  endpoint: null,
  loaded: false,
  method: null,
  operation: null,
  spec: null,
  specId: null,
}

function reducer(state: IState, action: IEditorReducerAction): IState {
  switch (action.type) {
    case EditorReducerType.specLoaded:
      if (
        action.spec &&
        action.operation &&
        action.endpoint &&
        action.method &&
        action.changeset &&
        action.specId
      ) {
        return {
          ...state,
          changeset: action.changeset,
          endpoint: action.endpoint,
          loaded: true,
          method: action.method,
          operation: action.operation,
          spec: action.spec,
          specId: action.specId,
        }
      }
    case EditorReducerType.fieldChanged:
      if (action.change) {
        const foundChangeIdx = state.changeset.findIndex(
          existingChange => existingChange.path === (action.change as any).path,
        )

        if (foundChangeIdx !== -1) {
          // Re-Add
          if (
            action.change &&
            action.change.operation === ChangesetOperation.destroy &&
            state.changeset[foundChangeIdx].path === action.change.path
          ) {
            console.log(
              'new change',
              state.changeset.filter(
                change => change.path !== (action.change as any).path,
              ),
            )
            return {
              ...state,
              changeset: state.changeset.filter(
                change => change.path !== (action.change as any).path,
              ),
            }
          }

          // Update
          return {
            ...state,
            changeset: state.changeset.map((item, index) => {
              if (index !== foundChangeIdx) {
                return item
              }

              console.log('new change', {
                ...item,
                ...action.change,
              })

              return {
                ...item,
                ...action.change,
              }
            }),
          }
        }

        console.log('new change', [...state.changeset, action.change])

        // Create
        return {
          ...state,
          changeset: [...state.changeset, action.change],
        }
      }
    default:
      throw new Error()
  }
}

const BackLink = (props: any) => <RouterLink to="/" {...props} />

function buildFirstRequestBodyRender(
  schemaObj: SchemaObject,
  changeset: IChangeset[],
  spec: OpenAPIObject,
  dispatch: Dispatch<IEditorReducerAction>,
  contentType: string,
) {
  return walk(schemaObj, contentType, {
    context: { type: SchemaWalkerContextType.requestBody },
  }).map((walkerMeta: ISpecWalkerMeta) => {
    return (
      <CollapsibleItem
        key={walkerMeta.schema[OASWalkerConstants.X_SCHEMA_WALKER].path}
        itemTitle={walkerMeta.title}
        schema={walkerMeta.schema as ISchemaObject}
        changeset={changeset}
        spec={spec}
        dispatch={dispatch}
      />
    )
  })
}

function FlavorEditor(props: RouteComponentProps<IRouteParams>) {
  const [state, dispatch] = useReducer(reducer, initialState)
  const { path, method, specId } = props.match.params

  const debouncedSave = debounce(save, 1000)

  useEffect(() => {
    const fetchOperation = async () => {
      const routerState = props.location.state
      const schemaId = routerState
        ? routerState.savedData.specId
        : decodeURIComponent(specId)
      const endpoint = routerState
        ? routerState.savedData.endpoint
        : decodeURIComponent(path)
      const meth = routerState
        ? routerState.savedData.method
        : (decodeURIComponent(method) as IOperationMethod)

      const spec = await loadSpec(schemaId)

      dispatch({
        changeset: routerState ? routerState.savedData.changes : [],
        endpoint,
        method: meth,
        operation: spec.paths[endpoint][meth],
        spec,
        specId: schemaId,
        type: EditorReducerType.specLoaded,
      })
    }
    fetchOperation()
  }, [])

  function save() {
    if ((window as any).ContentstackUIExtension) {
      ;(window as any).ContentstackUIExtension.init().then((extension: any) => {
        const savedData = {
          changes: state.changeset,
          endpoint: state.endpoint,
          method: state.method,
          specId: state.specId,
        } as IPersistedData

        extension.field.setData(JSON.stringify(savedData))
        console.log('SAVED IN CS')
      })
    }
    console.log('SAVED')
  }

  useEffect(() => {
    if (state.loaded) {
      debouncedSave()
    }
  }, [state.changeset])

  const renderMemoizedParameters = useMemo(() => {
    if (state.operation && state.spec && state.operation.parameters) {
      return state.operation.parameters.map((param, index) => {
        if (state.spec && !param.hasOwnProperty('$ref')) {
          return (
            <Parameter
              key={(param as ParameterObject).name}
              parameterIndex={index}
              parameter={param as ParameterObject}
              changeset={state.changeset}
              spec={state.spec}
              dispatch={dispatch}
            />
          )
        }
      })
    }
  }, [state.spec])

  const renderMemoizedRequestBody = useMemo(() => {
    if (
      state.operation &&
      state.spec &&
      state.operation.requestBody &&
      (state.operation.requestBody as RequestBodyObject).content
    ) {
      const contentType = getFirstContentTypeFromContent(
        (state.operation.requestBody as RequestBodyObject).content,
      )
      return buildFirstRequestBodyRender(
        (state.operation.requestBody as any).content[contentType].schema,
        state.changeset,
        state.spec,
        dispatch,
        contentType,
      )
    }
  }, [state.spec])

  const renderMemoizedResponses = useMemo(() => {
    if (state.operation && state.spec && state.operation.responses) {
      return Object.entries(state.operation.responses).map(entry => {
        const response = entry[1] as ResponseObject

        if (state.spec) {
          return (
            <Response
              key={entry[0]}
              responseTitle={entry[0]}
              response={response as ResponseObject}
              changeset={state.changeset}
              spec={state.spec}
              dispatch={dispatch}
            />
          )
        }
      })
    }
  }, [state.spec])

  return (
    <Grid container={true} spacing={16}>
      <Grid item={true} xs={12}>
        <Typography variant="button">
          <Link component={BackLink}>&larr; Back to Selector</Link>
        </Typography>
      </Grid>

      <Grid item={true} xs={12}>
        <Typography component="h1" variant="h4">
          Flavor Editor
        </Typography>
      </Grid>

      {state.loaded && state.operation ? (
        <>
          <Grid container={true} item={true} xs={12}>
            <Typography variant="h6" gutterBottom={true}>
              Method Attributes
            </Typography>
            {state.operation ? (
              <>
                <Grid item={true} xs={12}>
                  <TextField
                    disabled={true}
                    label="OperationId"
                    defaultValue={state.operation.operationId}
                    margin="normal"
                    fullWidth={true}
                  />
                </Grid>
                {state.operation.tags ? (
                  <Grid item={true} xs={12}>
                    <TextField
                      disabled={true}
                      label="Tags"
                      defaultValue={state.operation.tags.join(', ')}
                      margin="normal"
                      fullWidth={true}
                    />
                  </Grid>
                ) : null}
              </>
            ) : null}
          </Grid>
          {state.operation.parameters ? (
            <Grid item={true} xs={12}>
              <Typography variant="h6" gutterBottom={true}>
                Parameters
              </Typography>
              {renderMemoizedParameters}
            </Grid>
          ) : null}
          {state.operation.requestBody ? (
            <Grid item={true} xs={12}>
              <Typography variant="h6" gutterBottom={true}>
                Request Body
              </Typography>
              {renderMemoizedRequestBody}
            </Grid>
          ) : null}
          {state.operation.responses ? (
            <Grid item={true} xs={12}>
              <Typography variant="h6" gutterBottom={true}>
                Responses
              </Typography>
              {renderMemoizedResponses}
            </Grid>
          ) : null}
        </>
      ) : (
        <Grid item={true} xs={12}>
          <LinearProgress />
        </Grid>
      )}
    </Grid>
  )
}

export default FlavorEditor
