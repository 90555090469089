import { createMuiTheme } from '@material-ui/core/styles'

// tslint:disable: object-literal-sort-keys
const AppTheme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  breakpoints: {
    values: {
      xs: 480,
      sm: 768,
      md: 769,
      lg: 1280,
      xl: 1920,
    },
  },
})

export default AppTheme
