import {
  Chip,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core'
import red from '@material-ui/core/colors/red'
import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'
import cloneDeep from 'lodash/cloneDeep'
import {
  ChangesetOperation,
  getChange,
  getSchemaFromRef,
  IChangeset,
  ISchemaObject,
  OASWalkerConstants,
  SchemaWalkerContextType,
  walk,
} from 'oas-changeset-walker'
import { OpenAPIObject, ParameterObject } from 'openapi3-ts'
import React, { Dispatch, useCallback, useState } from 'react'
import {
  Accordion,
  AccordionItem,
  AccordionItemBody,
  AccordionItemTitle,
} from 'react-accessible-accordion'
import 'react-accessible-accordion/dist/fancy-example.css'

import Constants from '../../constants'
import { EditorReducerType } from '../../enums'
import { IEditorReducerAction } from '../../interfaces'

interface IProps {
  readonly parameterIndex: number
  readonly parameter: ParameterObject
  readonly changeset: IChangeset[]
  readonly spec: OpenAPIObject
  readonly dispatch: Dispatch<IEditorReducerAction>
}

function getDerefedSchema(
  schema: ISchemaObject,
  spec: OpenAPIObject,
  topLevelKey: string,
) {
  const derefedSchema = getSchemaFromRef(schema.$ref, spec)
  derefedSchema[OASWalkerConstants.X_SCHEMA_WALKER] = cloneDeep(
    schema[OASWalkerConstants.X_SCHEMA_WALKER],
  )

  // The type doesn't matter here since we're getting it from the parent context
  const walkedSchema = walk(derefedSchema, '', {
    context: { type: SchemaWalkerContextType.responses, topLevelKey },
  })[0].schema
  return walkedSchema
}

function Parameter(props: IProps) {
  const { changeset, spec, dispatch } = props
  const { parameterIndex, parameter } = props
  const schema = parameter.schema as ParameterObject

  const baseParameterKey = `parameters[${parameterIndex}]`

  const foundChanges = changeset.filter(
    existingChange =>
      existingChange.path === baseParameterKey + '.description' ||
      existingChange.path === baseParameterKey + '.example' ||
      existingChange.path === baseParameterKey,
  )

  const deletedChange = getChange(foundChanges, baseParameterKey)

  const foundDescriptionChange = getChange(
    foundChanges,
    baseParameterKey + '.description',
  )

  const foundExampleChange = getChange(
    foundChanges,
    baseParameterKey + '.example',
  )

  const beenDeleted =
    foundChanges &&
    deletedChange &&
    deletedChange.operation === ChangesetOperation.destroy

  const [deletable, setDeletable] = useState(!beenDeleted)

  return (
    <>
      <Accordion>
        <AccordionItem>
          <AccordionItemTitle
            className={
              beenDeleted
                ? 'accordion__title accordion__title--disabled'
                : 'accordion__title'
            }
          >
            <Grid container={true} alignItems="center">
              <Grid
                container={true}
                item={true}
                xs={true}
                spacing={16}
                alignItems="center"
              >
                <Grid item={true}>
                  <Typography component="h6" variant="subtitle1">
                    {parameter.name}
                  </Typography>
                </Grid>

                {parameter.required ? (
                  <Grid item={true}>
                    <Chip
                      label="REQUIRED"
                      style={{
                        backgroundColor: red[500],
                      }}
                    />
                  </Grid>
                ) : null}

                {schema && schema.type ? (
                  <Grid item={true}>
                    <Chip
                      label={schema.type}
                      style={{
                        backgroundColor:
                          Constants.TAG_TYPE_MAPPING[schema.type],
                      }}
                    />
                  </Grid>
                ) : null}
              </Grid>

              <Grid item={true} xs={1}>
                {deletable ? (
                  <Tooltip title="Mark for Deletion" placement="top-end">
                    <Typography align="right">
                      <IconButton
                        aria-label="Mark for Deletion"
                        disabled={!!parameter.required}
                        onClick={useCallback(
                          event => {
                            // Disable the item
                            const titleEl = event.target.closest(
                              '.accordion__title',
                            )
                            titleEl.setAttribute('aria-selected', false)
                            titleEl.classList.add('accordion__title--disabled')

                            const bodyEl = document.getElementById(
                              titleEl.getAttribute('aria-controls'),
                            )

                            if (bodyEl) {
                              bodyEl.classList.add('accordion__body--hidden')
                              bodyEl.setAttribute('aria-hidden', 'true')
                            }

                            event.stopPropagation()
                            setDeletable(false)

                            dispatch({
                              change: {
                                operation: ChangesetOperation.destroy,
                                path: event.target.closest('button').dataset
                                  .path,
                              },
                              type: EditorReducerType.fieldChanged,
                            })
                          },
                          [deletable],
                        )}
                        data-path={baseParameterKey}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Typography>
                  </Tooltip>
                ) : (
                  <Tooltip title="Readd" placement="top-end">
                    <Typography align="right">
                      <IconButton
                        aria-label="Readd"
                        disabled={!!parameter.required}
                        onClick={useCallback(
                          event => {
                            // Reenable the item
                            const titleEl = event.target.closest(
                              '.accordion__title',
                            )
                            titleEl.classList.remove(
                              'accordion__title--disabled',
                            )

                            const buttonEl = event.target.closest('button')
                            buttonEl.style.pointerEvents = 'auto'
                            event.stopPropagation()
                            setDeletable(true)

                            dispatch({
                              change: {
                                operation: ChangesetOperation.destroy,
                                path: event.target.closest('button').dataset
                                  .path,
                              },
                              type: EditorReducerType.fieldChanged,
                            })
                          },
                          [deletable],
                        )}
                        data-path={baseParameterKey}
                      >
                        <AddIcon />
                      </IconButton>
                    </Typography>
                  </Tooltip>
                )}
              </Grid>
            </Grid>
          </AccordionItemTitle>

          <AccordionItemBody>
            <Grid container={true} spacing={8} alignItems="center">
              <Grid container={true} item={true} spacing={16}>
                <Grid item={true} xs={12}>
                  <TextField
                    disabled={true}
                    label="In"
                    defaultValue={parameter.in}
                    margin="normal"
                  />
                </Grid>

                <Grid item={true} xs={12}>
                  <TextField
                    label="Description"
                    defaultValue={
                      foundDescriptionChange &&
                      foundDescriptionChange.operation ===
                        ChangesetOperation.update &&
                      foundDescriptionChange.value
                        ? foundDescriptionChange.value.toString()
                        : parameter.description
                    }
                    margin="normal"
                    multiline={true}
                    fullWidth={true}
                    onChange={useCallback(event => {
                      dispatch({
                        change: {
                          operation: ChangesetOperation.update,
                          path: event.target.dataset.path,
                          value: event.target.value,
                        },
                        type: EditorReducerType.fieldChanged,
                      })
                    }, [])}
                    inputProps={{
                      'data-path': baseParameterKey + '.description',
                    }}
                  />
                </Grid>

                {!(schema.type === 'object' || schema.type === 'array') ? (
                  <Grid item={true} xs={12}>
                    <TextField
                      label="Example"
                      defaultValue={
                        foundExampleChange &&
                        foundExampleChange.operation ===
                          ChangesetOperation.update &&
                        foundExampleChange.value
                          ? foundExampleChange.value.toString()
                          : parameter.example
                      }
                      margin="normal"
                      fullWidth={true}
                      inputProps={{
                        'data-path': baseParameterKey + '.example',
                      }}
                      onChange={useCallback(event => {
                        dispatch({
                          change: {
                            operation: ChangesetOperation.update,
                            path: event.target.dataset.path,
                            value: event.target.value,
                          },
                          type: EditorReducerType.fieldChanged,
                        })
                      }, [])}
                    />
                  </Grid>
                ) : null}
              </Grid>
            </Grid>
          </AccordionItemBody>
        </AccordionItem>
      </Accordion>
    </>
  )
}

export default Parameter
