import * as Yaml from 'js-yaml'
import { OpenAPIObject } from 'openapi3-ts'

import { OASWalkerConstants } from 'oas-changeset-walker'

export const loadSpec = async (specId: string): Promise<OpenAPIObject> => {
  return new Promise(async (resolve, reject) => {
    const remoteSpec = OASWalkerConstants.API_SPECS.find(
      schema => schema.id === specId,
    )

    if (remoteSpec) {
      const response = await fetch(remoteSpec.url)
      const reader = new FileReader()
      const blob = await response.blob()

      reader.onerror = () => {
        reader.abort()
        reject(new DOMException('Problem parsing spec file.'))
      }

      reader.onload = () => {
        if (reader.result) {
          resolve(Yaml.safeLoad(reader.result.toString()))
        }
      }
      reader.readAsText(blob)
    }
  })
}
