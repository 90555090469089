import CssBaseline from '@material-ui/core/CssBaseline'
import Grid from '@material-ui/core/Grid'
import {
  createStyles,
  Theme as ITheme,
  withStyles,
  WithStyles,
} from '@material-ui/core/styles'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { IPersistedData } from 'oas-changeset-walker'
import React, { useEffect, useState } from 'react'
import { HashRouter as Router, Redirect, Route, Switch } from 'react-router-dom'

import Theme from './theme'

import EntryPoint from './entry-point/entry-point'
import FlavorEditor from './flavor-editor/flavor-editor'

interface IState {
  readonly savedData: IPersistedData | null
}

const initialState = {
  savedData: null,
} as IState

// tslint:disable: object-literal-sort-keys
const styles = (theme: ITheme) =>
  createStyles({
    container: {
      margin: '0 auto',
      paddingTop: theme.spacing.unit * 6,
      paddingBottom: theme.spacing.unit * 6,
      paddingLeft: theme.spacing.unit * 3,
      paddingRight: theme.spacing.unit * 3,
      [theme.breakpoints.up('md')]: {
        maxWidth: '72rem',
      },
    },
  })
// tslint:enable: object-literal-sort-keys

function App(props: WithStyles<typeof styles>) {
  const { classes } = props
  const [state, setState] = useState(initialState)

  useEffect(() => {
    if ((window as any).ContentstackUIExtension) {
      ;(window as any).ContentstackUIExtension.init().then((extension: any) => {
        extension.window.updateHeight(4000)
        console.log(extension.field.getData(), 'empty')
        const extData = JSON.parse(extension.field.getData())
        if (extData) {
          setState({
            savedData: extData as IPersistedData,
          })
        }
      })
    }
    // setState({
    //   savedData: {
    //     changes: [
    //       {
    //         operation: 'destroy',
    //         path:
    //           "requestBody.content['application/json'].schema.properties.installmentPlan",
    //       },
    //       {
    //         operation: 'destroy',
    //         path: 'parameters[1]',
    //       },
    //       {
    //         operation: 'destroy',
    //         path: "responses['415']",
    //       },
    //       {
    //         operation: 'destroy',
    //         path:
    //           "requestBody.content['application/json'].schema.properties.assetListingId",
    //       },
    //       {
    //         operation: 'update',
    //         path:
    //           "requestBody.content['application/json'].schema.properties.assetListingId.description",
    //         value: 'ID of the assetlisting testing',
    //       },
    //       {
    //         operation: 'update',
    //         path: "responses['201'].description",
    //         value: 'Successfully payment processed test',
    //       },
    //       {
    //         operation: 'update',
    //         path:
    //           "requestBody.content['application/json'].schema.properties.installmentPlan.description",
    //         value: 'Testing 123',
    //       },
    //       {
    //         operation: 'update',
    //         path:
    //           "requestBody.content['application/json'].schema.properties.channel.example",
    //         value: 'tests',
    //       },
    //     ],
    //     endpoint: '/v2/payments/payment',
    //     method: 'post',
    //   } as IPersistedData,
    // })
  }, [])

  return (
    <MuiThemeProvider theme={Theme}>
      <CssBaseline />

      <Grid className={classes.container} component="main">
        <Router>
          <>
            {state.savedData ? (
              <Redirect
                to={{
                  pathname: `/editor/${encodeURIComponent(
                    state.savedData.specId,
                  )}/${encodeURIComponent(
                    state.savedData.endpoint,
                  )}/${encodeURIComponent(state.savedData.method)}`,
                  state: {
                    savedData: state.savedData,
                  },
                }}
              />
            ) : null}
            <Switch>
              <Route exact={true} path="/" component={EntryPoint} />
              <Route
                path="/editor/:specId/:path/:method"
                component={FlavorEditor}
              />
            </Switch>
          </>
        </Router>
      </Grid>
    </MuiThemeProvider>
  )
}

export default withStyles(styles)(App)
