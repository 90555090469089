import SwaggerParser from 'swagger-parser'

import { OASWalkerConstants } from 'oas-changeset-walker'

export const parseSpec = (specId: string) => {
  const remoteSpec = OASWalkerConstants.API_SPECS.find(
    schema => schema.id === specId,
  )

  if (remoteSpec) {
    return SwaggerParser.parse(remoteSpec.url)
  }
}
