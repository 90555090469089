import amber from '@material-ui/core/colors/amber'
import cyan from '@material-ui/core/colors/cyan'
import green from '@material-ui/core/colors/green'
import pink from '@material-ui/core/colors/pink'
import purple from '@material-ui/core/colors/purple'

import { EditableKey } from './enums'
import { IConstants } from './interfaces'

export default {
  SHARED_EDITABLE_KEYS: [
    EditableKey.description,
    EditableKey.summary,
    EditableKey.example,
  ],
  TAG_TYPE_MAPPING: {
    array: purple[300] as string,
    boolean: cyan[300] as string,
    integer: pink[300] as string,
    number: pink[300] as string,
    object: green[300] as string,
    string: amber[300] as string,
  },
} as IConstants
